import {
  Row,
  Col,
  Form,
  Input,
  Image,
  Button,
  Typography,
  Checkbox,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import purseLogo from "../images/orange_black_logo.png";
import { useDispatch } from "react-redux";
import { createMerchant, loginMerchant } from "../api/merchant.module";
import Cookies from "js-cookie";
import { setUser } from "../features/authSlice/authSlice";
import { countryList } from "../jsonFiles/countryList";
import Register from "../components/auth/Register";

const { Text, Paragraph } = Typography;

function Login() {
  const [loginPage, setLoginPage] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [responseAfter, setResponseAfter] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    setLoading(true);
    const { password, passwordTwo, name, phoneNumber, email } = values;
    if (password !== passwordTwo) {
      setErr("The Passwords entered do not match! Try again");
      setLoading(false);
    } else if (password.length < 8) {
      setErr(
        "The password entered is too shoot, must be at least 8 characters1"
      );
      setLoading(false);
    } else if (password.includes(name) || password.includes(phoneNumber)) {
      setErr(
        "Password type not recommended, it should not contain your personal details"
      );
      setLoading(false);
    } else {
      setLoading(true);
      setResponseAfter(values);
      try {
        const dataLoad = {
          name,
          phoneNumber: "+234" + phoneNumber,
          email,
          password,
          agreed: true,
        };
        // console.log(dataLoad);
        const dt = await createMerchant(dataLoad);
        if (dt) {
          setLoading(false);
          message.success("Account created successfully");
          setEmailSent(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleLogin = async (v) => {
    setLoading(true);
    try {
      const dt = await loginMerchant(v);
      if (dt.token) {
        // const auth = jwt.decode(dt.token);
        Cookies.set("purseMerchant", dt.token, { expires: 1 });
        dispatch(setUser(dt.data));
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setErr("Incorrect Username or password!");
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        width: "100vw",
        background: "#FAFAFA",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: screenWidth < 600 ? "90vw" : "35vw",
          minWidth: "280px",
        }}
      >
        <Paragraph style={{ textAlign: "center", padding: "1rem 0" }}>
          <Image
            src={purseLogo}
            alt="purSe-logo"
            // width={screenWidth < 800 ? "250px" : "300px"}
            preview={false}
          />
        </Paragraph>
        <div
          style={{
            background: "#fff",
            padding: screenWidth < 600 ? "1rem 2rem 2rem 2rem" : "2vh 4vw",
            borderRadius: "7px",
          }}
        >
          {emailSent ? (
            <div
              style={{
                padding: "1rem 0",
                fontSize: "14px",
                fontFamily: "Arial",
              }}
            >
              <Paragraph style={{ textAlign: "center", padding: "1.7rem 0" }}>
                <Text style={{ fontSize: "18px", fontWeight: 600 }}>
                  Verify Account
                </Text>
                <br />
                {/* <Text style={{ color: "#424242" }}>
                  Please enter the code we sent to your email address.
                </Text> */}
              </Paragraph>
              <Typography.Paragraph>
                Dear <b>{responseAfter?.name}, </b>
              </Typography.Paragraph>
              <Typography.Paragraph>
                Thank you for signing up with Purse Wallet! To complete the
                registration process, please verify your email address
                <b> {responseAfter && ` ${responseAfter?.email}`} </b> by
                clicking the verification link we've sent to your inbox. If you
                can't find the email, kindly check your spam or junk folder as
                well.
              </Typography.Paragraph>
              <Paragraph style={{ textAlign: "center", padding: "2rem 0" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setEmailSent(false);
                    setLoginPage(false);
                  }}
                >
                  Back to Home
                </Button>
              </Paragraph>
            </div>
          ) : (
            <>
              {!loginPage ? (
                <div>
                  <Paragraph
                    style={{ textAlign: "center", padding: "1.7rem 0" }}
                  >
                    <Text style={{ fontSize: "18px", fontWeight: 600 }}>
                      Welcome back!
                    </Text>
                    <br />
                    <Text style={{ color: "#424242" }}>
                      Sign in to your Purse account to continue.{" "}
                    </Text>
                  </Paragraph>
                  {err && (
                    <Typography.Paragraph
                      style={{
                        textAlign: "center",
                        backgroundColor: "#C3422B",
                        color: "#fff",
                        padding: "0.5rem",
                      }}
                    >
                      {err}
                    </Typography.Paragraph>
                  )}
                  <Form name="login" layout="vertical" onFinish={handleLogin}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please this email is required!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email address"
                        type="email"
                        onChange={() => setErr("")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Please enter password!" },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        type="password"
                        onChange={() => setErr("")}
                      />
                    </Form.Item>

                    <Row align="middle">
                      <Col span={12}>
                        <Form.Item name="rememberMe">
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Paragraph>
                          <Text strong style={{ cursor: "pointer" }}>
                            Forgot password?
                          </Text>
                        </Paragraph>
                      </Col>
                    </Row>

                    <Paragraph>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                      >
                        Sign In
                      </Button>
                    </Paragraph>
                    <Paragraph style={{ textAlign: "center" }}>
                      Don't have an account?{" "}
                      <Text
                        strong
                        style={{ cursor: "pointer" }}
                        onClick={() => setLoginPage(true)}
                      >
                        Create one
                      </Text>
                    </Paragraph>
                  </Form>
                </div>
              ) : (
                <div>
                  <Paragraph
                    style={{ textAlign: "center", padding: "0.5rem 0" }}
                  >
                    <Text style={{ fontSize: "18px", fontWeight: 600 }}>
                      Sign Up
                    </Text>
                    <br />
                    <Text style={{ color: "#424242" }}>
                      Start your sales journey with Purse.
                    </Text>
                  </Paragraph>
                  {err && (
                    <Typography.Paragraph
                      style={{
                        textAlign: "center",
                        backgroundColor: "#C3422B",
                        color: "#fff",
                        padding: "0.5rem",
                      }}
                    >
                      {err}
                    </Typography.Paragraph>
                  )}
                  <Form
                    layout="vertical"
                    name="account_creation_form"
                    onFinish={handleSubmit}
                  >
                    <Register
                      setErr={setErr}
                      countryList={countryList}
                      screenWidth={screenWidth}
                    />
                    <Paragraph>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                      >
                        Create Account
                      </Button>
                    </Paragraph>
                  </Form>
                </div>
              )}
            </>
          )}
        </div>
        {loginPage && (
          <>
            {!emailSent && (
              <Paragraph style={{ textAlign: "center", padding: "1rem 0" }}>
                Already have an account?{" "}
                <Text
                  strong
                  style={{ cursor: "pointer" }}
                  onClick={() => setLoginPage(false)}
                >
                  Sign In
                </Text>
              </Paragraph>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
