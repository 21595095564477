import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: { name: "Dashboard", path: "/" },
};

export const crumbSlice = createSlice({
  name: "crumb",
  initialState,
  reducers: {
    setCrumb: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCrumb } = crumbSlice.actions;

export default crumbSlice.reducer;
