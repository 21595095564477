import {
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Empty,
  Input,
  Button,
  Image,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  SendOutlined,
  MessageFilled,
  CheckOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SpinerComponent from "../../../helpers/SpinComponent";
import TimeAgo from "react-timeago";
import AvatarContainer from "../../../helpers/AvatarContainer";
import { getBiChats, postChat } from "../../../api/chats.module";
const { Text, Paragraph } = Typography;
const { TextArea } = Input;
function ChatClients({ screenWidth }) {
  const [chats, setChats] = useState("");
  const [message, setMessage] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const fetchChats = async () => {
    try {
      const getChats = await getBiChats(id, user.id);
      if (getChats) {
        setChats(getChats);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchChats();
  }, [id]);
  const createChat = async () => {
    setLoading(true);
    try {
      const dataLoad = {
        senderId: user.id,
        recipientId: id,
        message,
      };
      const dt = await postChat(dataLoad);
      if (dt) {
        await fetchChats();
        setLoading(false);
        setMessage("");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const options = {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
    // timeZone: "UTC", // Set the time zone to UTC
  };

  const groupedChats =
    chats &&
    chats.chats.reduce((grouped, chat) => {
      // Extract the date from the 'createdAt' field
      const date = chat.createdAt.split("T")[0];
      // Add the chat to the corresponding date in the object
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(chat);
      return grouped;
    }, {});
  console.log("chats", chats);
  return (
    <div>
      <Paragraph
        style={{
          margin: 0,
          padding: screenWidth < 600 ? "0 0 2vh 0" : "0 1vw 2vh 1vh",
          borderBottom: "1px solid #0C7C8550",
        }}
      >
        {chats ? (
          <Space size={10}>
            <ArrowLeftOutlined
              style={{ fontSize: "18px" }}
              onClick={() => navigate(-1)}
            />
            {chats.customer.image ? (
              <Image
                src={chats.customer.image}
                style={{ width: "45px", height: "45px", borderRadius: "50%" }}
              />
            ) : (
              <AvatarContainer name={chats.customer.name} size={40} />
            )}
            <Paragraph style={{ margin: 0, lineHeight: 0.5 }}>
              <Text style={{ fontSize: "15px" }} strong>
                {chats.customer.name}
              </Text>
              <br />
              {chats.chats.filter((d) => d.senderId !== user.id)[0]
                ?.createdAt && (
                <Text style={{ fontSize: "10px" }}>
                  Last seen{" "}
                  <TimeAgo
                    date={
                      chats.chats.filter((d) => d.senderId !== user.id)[0]
                        ?.createdAt
                    }
                  />
                </Text>
              )}
            </Paragraph>
          </Space>
        ) : (
          <Text style={{ fontSize: "15px" }} strong>
            Loading ...
          </Text>
        )}
      </Paragraph>
      <div
        style={{
          height: screenWidth < 600 ? "78vh" : "70vh",
          padding: screenWidth < 600 ? "1rem 0 6rem 0" : "1rem 0 7rem 0",
          // background: "#414141",
          overflowY: "auto",
        }}
      >
        {!chats ? (
          <SpinerComponent title="Chats" />
        ) : (
          <>
            {chats.chats.length < 1 ? (
              <Empty description="No chats found!!!" />
            ) : (
              <>
                <Paragraph
                  style={{
                    padding: "0.5rem",
                    margin: "0 0.5rem",
                    textAlign: "center",
                    background: "#cccccc50",
                    color: "#F08D17",
                    borderRadius: "30px",
                    fontSize: "11px",
                  }}
                >
                  Chats are encrypted with end-to-end encryption
                </Paragraph>
                {Object.keys(groupedChats)
                  .sort((a, b) => {
                    const dateA = new Date(a);
                    const dateB = new Date(b);
                    return dateA - dateB;
                  })
                  .map((date) => (
                    <div key={date}>
                      <Divider dashed>
                        <Text style={{ fontSize: "12px" }}>
                          {new Date(date).toDateString()}
                        </Text>
                      </Divider>
                      {groupedChats[date]
                        .sort((a, b) => {
                          const dateA = new Date(a.createdAt);
                          const dateB = new Date(b.createdAt);
                          return dateA - dateB;
                        })
                        .map((chat) => (
                          <Row key={chat.id}>
                            <Col
                              span={24}
                              style={{ padding: "0 0.5rem 0 0.5rem" }}
                            >
                              <div
                                className={
                                  chat.senderId === user.id ? "msg-r" : "msg-l"
                                }
                              >
                                {chat.message}
                                <Paragraph
                                  style={{
                                    margin: 0,
                                    padding: "0.2rem 0 0 0",
                                    color:
                                      chat.senderId === user.id
                                        ? "#888"
                                        : "#ccc",
                                    fontSize: "10px",
                                    textAlign: "right",
                                  }}
                                >
                                  <Space>
                                    {new Intl.DateTimeFormat(
                                      "en-US",
                                      options
                                    ).format(new Date(chat?.createdAt))}
                                    {chat.senderId === user.id && (
                                      <>
                                        <CheckOutlined
                                          style={{ color: "#0C7C85" }}
                                        />
                                      </>
                                    )}
                                  </Space>
                                </Paragraph>
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          background: "#fff",
          padding: screenWidth < 600 ? 0 : "0 0 1rem 0.7rem",
        }}
      >
        <Row className="chat-type" align="middle" gutter={12}>
          <Col span={4} style={{ textAlign: "center" }}>
            <MessageFilled style={{ fontSize: "2rem" }} />
          </Col>
          <Col span={16}>
            <TextArea
              rows={1}
              value={message}
              placeholder="Type a message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => createChat()}
              loading={loading}
              shape="circle"
              icon={<SendOutlined style={{ fontSize: "18px" }} />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ChatClients;
