import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, theme, Image } from "antd";
import HeaderTop from "./HeaderTop";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import purseLogo from "../images/purse_logo_orange.png";
import HeaderSmall from "../components/small-screens/HeaderSmall";
import { getMerchantData } from "../api/merchant.module";
import { setUser } from "../features/authSlice/authSlice";

const { Content } = Layout;

const HomeLayout = ({ screenWidth }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.value);

  useEffect(() => {
    const testUser = async () => {
      if (!user.name) {
        navigate("/login");
      }
    };
    testUser();
  }, [user, navigate]);
  const getData = async () => {
    const myData = await getMerchantData(user.id);
    dispatch(setUser(myData));
  };
  useEffect(() => {
    getData();
  }, [user.id]);
  return (
    <>
      {!user.name ? (
        <div style={{ textAlign: "center", padding: "5%" }}>
          <Image src={purseLogo} alt="purse-logo" width={90} preview={false} />
          <p>Loading ... </p>
        </div>
      ) : (
        <Layout style={{ minHeight: "100vh" }}>
          {screenWidth > 600 && <SideNav collapsed={collapsed} />}
          <Layout>
            {screenWidth < 600 ? (
              <HeaderSmall user={user} />
            ) : (
              <HeaderTop collapsed={collapsed} setCollapsed={setCollapsed} />
            )}
            <Content
              style={{
                margin: screenWidth < 600 ? 0 : "16px 16px",
                padding: screenWidth < 600 ? 20 : 24,
                height: "80vh",
                background: colorBgContainer,
                overflowY: "auto",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default HomeLayout;
