import { Card, Modal, Typography } from "antd";
import { menuItems } from "../../helpers/MenuList";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
function SmallMenuModal({ open, handleClose }) {
  const gridStyle = {
    width: "48%",
    padding: "2vh 1vw",
    textAlign: "center",
    borderRadius: "7px",
    margin: "2px",
    background: "linear-gradient(45deg,  #7c2, #04C869)",
  };
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={null}
      closable={false}
      footer={null}
    >
      <Card style={{ border: "none", padding: 0 }}>
        {menuItems.map((item) => (
          <Card.Grid
            key={item.label}
            style={gridStyle}
            onClick={() => {
              navigate(item.path);
              handleClose();
            }}
          >
            <Text style={{ color: "#fff", fontSize: "1.8rem" }}>
              {item.icon}
            </Text>
            <br />
            <Text style={{ color: "#fff", fontSize: "10px" }}>
              {item.label}{" "}
            </Text>
          </Card.Grid>
        ))}
      </Card>
    </Modal>
  );
}

export default SmallMenuModal;
