import {
  Avatar,
  Button,
  Drawer,
  Image,
  Upload,
  Typography,
  message,
  Space,
  Form,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  LogoutOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { logoutUser } from "../features/authSlice/authSlice";
import { useState } from "react";
import { v4 } from "uuid";
import ImgCrop from "antd-img-crop";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { updateMerchant } from "../api/merchant.module";

const { Paragraph, Text } = Typography;
function AccountSettings({ open, onClose }) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();
  let imageUrl;

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // console.log("ddd", fileList);

  const handleImgUpload = async () => {
    try {
      if (fileList !== null) {
        const imgRef = ref(storage, `/files/${v4()}`);
        await uploadBytes(imgRef, fileList[0].originFileObj);
        imageUrl = await getDownloadURL(imgRef);
        return true;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return false;
    }
  };
  const handleSubmit = async (v) => {
    setLoading(true);
    try {
      const saveImg = await handleImgUpload();
      if (saveImg === true) {
        const dataLoad = {
          image: imageUrl ? imageUrl : user.image,
          ...v,
        };
        try {
          const dt = await updateMerchant(user.id, dataLoad);
          if (dt) {
            message.success(dt.message);
            setLoading(false);
            onClose();
          }
          setLoading(false);
        } catch (error) {
          console.log(error.response.data.message);
          message.error(error.response.data.message);
          setLoading(false);
        }
      } else {
        message.warning("An error happened!");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={
        <Paragraph style={{ textAlign: "right", margin: 0 }}>
          <Space>
            {!editMode ? (
              <Button
                size="small"
                type="default"
                onClick={() => setEditMode(true)}
                icon={<EditOutlined />}
              />
            ) : (
              <>
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => setEditMode(false)}
                >
                  Discard
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => form.submit()}
                  loading={loading}
                >
                  Save
                </Button>
              </>
            )}
          </Space>
        </Paragraph>
      }
    >
      {editMode ? (
        <Paragraph style={{ textAlign: "center" }}>
          <ImgCrop rotationSlider cropShape="round" quality={1}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              // onPreview={onPreview}
            >
              {fileList.length < 1 && (
                <div style={{ textAlign: "center" }}>
                  <PlusOutlined style={{ fontSize: "20px" }} /> <br /> Change
                  Logo
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Paragraph>
      ) : (
        <Paragraph style={{ textAlign: "center" }}>
          {user.image ? (
            <Image
              src={user.image}
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          ) : (
            <Avatar icon={<UserOutlined />} size={70} />
          )}
        </Paragraph>
      )}
      {editMode ? (
        <>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            initialValues={user}
          >
            <Form.Item
              name="name"
              label="Business Name"
              help={
                <i style={{ fontSize: "12px" }}>
                  {" "}
                  This should be your Business name or your name
                </i>
              }
            >
              <Input placeholder="Full Name" size="small" />
            </Form.Item>
            <Form.Item name="email" label="Email Address">
              <Input placeholder="Email address" disabled size="small" />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Contact">
              <Input placeholder="Tel Number" size="small" />
            </Form.Item>
            <Form.Item name="location" label="Business Location/Address">
              <Input.TextArea
                rows={3}
                placeholder="Business address"
                size="small"
              />
            </Form.Item>
          </Form>
        </>
      ) : (
        <Paragraph style={{ textAlign: "center", color: "#888" }}>
          <Text style={{ fontSize: "16px" }} strong>
            {user?.name.toUpperCase()}{" "}
          </Text>
          <br /> {user?.email}, {user?.phoneNumber}
          <br />
          {!user.location ? "Location not set!!" : user.location}
        </Paragraph>
      )}
      {!editMode && (
        <Paragraph style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              dispatch(logoutUser());
            }}
            icon={<LogoutOutlined />}
          >
            Log Out
          </Button>
        </Paragraph>
      )}
    </Drawer>
  );
}

export default AccountSettings;
