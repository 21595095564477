import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AvatarContainer from "../../helpers/AvatarContainer";
import TimeAgo from "react-timeago";
import { Col, Row, Typography, Input, Space, Image } from "antd";
import { getMerchantTransactions } from "../../api/merchant.module";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SpinerComponent from "../../helpers/SpinComponent";

const { Paragraph, Text } = Typography;
function Customers({ screenWidth }) {
  const [transactions, setTransactions] = useState("");
  const navigate = useNavigate();
  const url = useParams();
  const user = useSelector((state) => state.auth.value);
  function toSentenceCase(str) {
    const words = str?.toLowerCase().split(" ");
    const sentenceCaseWords = words.map(
      (word) => word.charAt(0)?.toUpperCase() + word.slice(1)
    );
    const sentenceCaseStr = sentenceCaseWords.join(" ");
    return sentenceCaseStr;
  }

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const transactionList = await getMerchantTransactions(user.id);
        setTransactions(transactionList);
      } catch (err) {
        console.log("error", err);
      }
    };
    fetchDeposits();
  }, [user.id]);
  const newList =
    transactions &&
    transactions.map((transaction) => {
      return {
        customerId: transaction.customerId,
        name: transaction.customer?.name,
        image: transaction.customer?.image,
      };
    });
  // function uniqueCustomers(newLi) {
  //   return [...new Set(newLi)];
  // }
  function uniqueObjectsByProperty(objects, propertyKey) {
    const seen = new Set();
    const uniqueCustomers = [];
    for (const obj of objects) {
      const value = obj[propertyKey];
      if (!seen.has(value)) {
        seen.add(value);
        uniqueCustomers.push(obj);
      }
    }

    return uniqueCustomers;
  }
  const uniqueCustomers = uniqueObjectsByProperty(newList, "customerId");
// console.log('unid',uniqueCustomers)
  return (
    <div>
      <Row style={{ margin: 0, padding: 0 }}>
        <Col span={screenWidth < 600 ? 24 : 6}>
          {!transactions ? (
            <SpinerComponent />
          ) : (
            <>
              <Paragraph>
                <Input.Search placeholder="Search Name" />
              </Paragraph>
              {uniqueCustomers?.map((x) => {
                return (
                  <Paragraph
                    style={{
                      background: "#f1f1f170",
                      borderBottom: "0.2px solid #eee",
                      cursor: "pointer",
                      // borderRadius: "3px",
                      margin: 0,
                      padding: "0.5rem 0.2rem",
                      lineHeight: 1,
                    }}
                    key={x.customerId}
                    onClick={() => navigate(`/customers/${x?.customerId}`)}
                  >
                    <Space>
                      {x.image ? (
                        <Image
                          src={x.image}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            border: "0.5px solid #ccc",
                          }}
                        />
                      ) : (
                        <AvatarContainer name={x?.name} size={40} />
                      )}
                      <Text>
                        {x.name ? toSentenceCase(x?.name) : "Missing User"}{" "}
                      </Text>{" "}
                    </Space>
                  </Paragraph>
                );
              })}
            </>
          )}
        </Col>
        <Col
          md={18}
          style={{ padding: screenWidth < 600 ? 0 : "0 1rem", margin: 0 }}
        >
          <Outlet />
        </Col>
      </Row>
    </div>
  );
}

export default Customers;
