import { useState } from "react";
import { Card, Form, Input, Button, Typography, Divider, Row, Col } from "antd";
import PasswordModal from "./PasswordModal";
import CloseAccount from "./CloseAccount";

const { Text, Paragraph, Title } = Typography;
function BasicInfo({ edit }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card>
        <Paragraph strong>Password Management</Paragraph>
        <Text>
          Control your password to ensure security prevails in your account
        </Text>
        <Paragraph style={{ textAlign: "right", margin: 0 }}>
          <Button type="link" onClick={() => setOpen(true)} disabled={edit}>
            Change Password
          </Button>
        </Paragraph>
        {open && <PasswordModal open={open} handleClose={handleClose} />}
      </Card>
    </>
  );
}

export default BasicInfo;
