import { Image, Result } from "antd";
import chatImage from "../../../images/chatNabbe.png";

function ChatIndex({ screenWidth }) {
  return (
    <div style={{ padding: "3% 0" }}>
      <Result
        icon={
          <Image
            src={chatImage}
            alt="llc"
            style={{ width: screenWidth < 600 ? "60vw" : "30vw" }}
            preview={false}
          />
        }
        subTitle="Select any customer and start a chat!"
      />
    </div>
  );
}

export default ChatIndex;
