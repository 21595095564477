import { Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
const { Paragraph } = Typography;
const Styles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  fontFamily: "Arial, sans-serif",
};
function NotFound(props) {
  const navigate = useNavigate();
  return (
    <div style={Styles}>
      <Paragraph style={{ fontSize: "5em", margin: 0, lineHeight: 1 }} strong>
        404{" "}
      </Paragraph>
      <Paragraph style={{ fontSize: "2em", margin: 0 }}>
        Page not found
      </Paragraph>
      <Paragraph>Oops! The page you are looking for does not exist.</Paragraph>
      <Paragraph>
        <Button type="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Paragraph>
    </div>
  );
}

export default NotFound;
