import { useEffect } from "react";
import {
  Tag,
  Table,
  Row,
  Col,
  Typography,
  Space,
  Input,
  ConfigProvider,
  Image,
} from "antd";
import { useState } from "react";
import { getMerchantTransactions } from "../../api/merchant.module";
import Spiner from "../../helpers/SpinComponent";
import TimeAgo from "react-timeago";
import { useSelector } from "react-redux";
import AvatarContainer from "../../helpers/AvatarContainer";

const { Column } = Table;
const { Text, Paragraph } = Typography;

function CustomerTransactions({ screenWidth }) {
  const [customerTransactions, setCustomerTransactions] = useState("");
  const [selected, setSelected] = useState([]);
  const user = useSelector((state) => state.auth.value);
  useEffect(() => {
    const callTransactions = async () => {
      const cusTrs = await getMerchantTransactions(user.id);
      setCustomerTransactions(cusTrs);
    };
    callTransactions();
  }, []);
  const rowSelection = {
    onChange: (selected, selectedRows) => {
      setSelected([...selected]);
    },
  };
  customerTransactions &&
    customerTransactions.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  return (
    <div
      style={{
        padding: "0 0 1rem 0",
        overflowY: "auto",
        overflowX: "hidden",
        margin: "0.2rem 0",
      }}
    >
      {!customerTransactions ? (
        <Spiner title="Transactions" />
      ) : (
        <>
          {screenWidth < 800 ? (
            <>
              {customerTransactions?.map((x) => {
                return (
                  <Paragraph
                    style={{
                      background: "#f1f1f110",
                      border: "0.2px solid #eee",
                      borderRadius: "3px",
                      margin: "0.3rem 0",
                      padding: "0.5rem 1rem",
                      lineHeight: 1,
                    }}
                    key={x.id}
                  >
                    <Row gutter={4} align="middle">
                      <Col span={5}>
                        {x.customer.image ? (
                          <Image
                            src={x.customer.image}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <AvatarContainer name={x.customer.name} size={45} />
                        )}
                      </Col>
                      <Col span={12} style={{ padding: "0.5rem 0" }}>
                        <Text strong>{x.customer.name} </Text> <br />
                        <Text style={{ color: "#888" }}>
                          {x.reason ? x.reason : "Not provided"}
                        </Text>{" "}
                        |{" "}
                        <Text style={{ color: "#dd1", fontSize: "12px" }}>
                          <TimeAgo date={x.createdAt} />
                        </Text>
                      </Col>
                      <Col span={6} style={{ textAlign: "right" }}>
                        <Text
                          style={{ color: "green", fontSize: "15px" }}
                          strong
                        >
                          ₦ {(x.amount - x.merchantCharges)?.toLocaleString()}
                        </Text>
                      </Col>
                    </Row>
                  </Paragraph>
                );
              })}
            </>
          ) : (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#888",
                  fontFamily: "sans-serif",
                  colorText: "#424242",
                  fontSizeIcon: "18px",
                },
              }}
            >
              <Table
                dataSource={customerTransactions}
                rowSelection={{
                  ...rowSelection,
                }}
                rowKey="id"
                size="small"
              >
                <Column
                  title="CUSTOMER"
                  dataIndex="customer"
                  render={(y) => (
                    <>
                      <Space>
                        {y.image ? (
                          <Image
                            src={y.image}
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                            preview={false}
                          />
                        ) : (
                          <AvatarContainer name={y.name} />
                        )}

                        {y.name?.toUpperCase()}
                      </Space>
                    </>
                  )}
                />
                <Column
                  title="DESCRIPTION"
                  dataIndex="reason"
                  render={(x) => <>{x ? x : "Not provided"}</>}
                />
                <Column
                  title="CREDITS"
                  align="right"
                  dataIndex="amount"
                  render={(amount, x) =>
                    `₦ ${(amount - x.merchantCharges)?.toLocaleString()}`
                  }
                />
                <Column
                  title="CHARGES"
                  align="right"
                  key="merchantCharges"
                  dataIndex="merchantCharges"
                  render={(d) => `₦ ${d ? d?.toLocaleString() : "0.0"}`}
                />
                <Column
                  title="TOTAL"
                  align="right"
                  key="amount"
                  dataIndex="amount"
                  render={(amount) => `₦ ${amount?.toLocaleString()}`}
                />
                <Column
                  title="PAYMENT TYPE"
                  dataIndex="merchantId"
                  align="center"
                  render={(y) => (
                    <>
                      {y ? (
                        <Tag color="cyan">PursePay</Tag>
                      ) : (
                        <Tag color="volcano">3rd Party</Tag>
                      )}{" "}
                    </>
                  )}
                />
                <Column
                  title="TRANSACTION TIME"
                  key="createdAt"
                  dataIndex="createdAt"
                  render={(x) => new Date(x).toLocaleString()}
                />
                <Column
                  title="ACTIONS"
                  key="id"
                  align="center"
                  dataIndex="id"
                  render={(id, row) => <>...</>}
                />
              </Table>
            </ConfigProvider>
          )}
        </>
      )}
    </div>
  );
}

export default CustomerTransactions;
