import { Avatar, Typography } from "antd";
function AvatarContainer({ name, size }) {
  return (
    <Avatar
      style={{ backgroundColor: "#FEDF89", border: "1px solid #F3640720" }}
      size={size}
    >
      <Typography.Text strong style={{ color: "#F36407" }}>
        {name?.split(" ")[1]?.substring(0, 1)
          ? name?.split(" ")[0].substring(0, 1).toUpperCase()
          : name?.split(" ")[0].substring(0, 1)}
      </Typography.Text>
    </Avatar>
  );
}

export default AvatarContainer;
