import { Card, Typography, Button } from "antd";

const { Paragraph} = Typography;
function CloseAccount({ edit }) {
  return (
    <div>
      <Card style={{ margin: "1rem 0" }}>
        <Paragraph strong>Delete Account </Paragraph>

        <Paragraph
          style={{
            background: "#F5EDEB",
            borderRadius: "5px",
            padding: "1rem 1rem",
            lineHeight: 1.2,
            color: "#888",
            border: "1px solid #C4A49E",
          }}
        >
          If you feel that PursePay wallet is not hitting your goals as
          expected, you may choose to close it permanently. <br />
          Upon closure of your account, the money on your wallet will
          equivalently be deposited to the bank account as provided.
          <br />
          Account will permanently be closed after 30 days.
        </Paragraph>
        <Button type="primary" style={{float:'right'}} danger disabled={edit}>
          Close Account
        </Button>
      </Card>
    </div>
  );
}

export default CloseAccount;
