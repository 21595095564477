import { Card, Form, Input, Row, Col, Typography, Divider } from "antd";
const { Text, Paragraph } = Typography;
function BankDetails({ edit }) {
  return (
    <>
      <Card>
        <Paragraph strong>Bank Details</Paragraph>
        <Text>
          Choose the financial institution where your money will be deposited.
        </Text>
        <Divider style={{ margin: "1.3rem 0" }} />
        <Form.Item
          label="Account Holder Name"
          name={["bankDetails", "accountHolderName"]}
        >
          <Input placeholder="Enter account holder name" disabled={edit} />
        </Form.Item>
        <Row gutter={12}>
          <Col span={16}>
            <Form.Item
              label="Account Number"
              name={["bankDetails", "accountNumber"]}
            >
              <Input.Password
                type="number"
                placeholder="Enter account number"
                disabled={edit}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Swift Code" name={["bankDetails", "swiftCode"]}>
              <Input placeholder="Swift Code" disabled={edit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Bank Name" name={["bankDetails", "bankName"]}>
              <Input placeholder="Enter bank name" disabled={edit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Branch Name" name={["bankDetails", "branchName"]}>
              <Input placeholder="Enter branch name" disabled={edit} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default BankDetails;
