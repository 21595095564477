import { Modal, Button, Space, Input, Form, Typography } from "antd";
import { useState } from "react";
import { updateMerchantPassword } from "../../api/merchant.module";
import Cookies from "js-cookie";
function PasswordModal({ open, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const { succ, setSucc } = useState("");
    const uza = Cookies.get("merchant");
    const user = uza && JSON.parse(atob(uza.split(".")[1]));
  const [form] = Form.useForm();
  const handleChange = async (v) => {
    try {
      setLoading(true);
      const { newPassword, newPassword1, currentPassword } = v;
      if (newPassword === newPassword1) {
        const upHook = await updateMerchantPassword(user.id, {
          newPassword,
          currentPassword,
        });
        if (upHook.data) {
          setSucc(upHook.message);
          setLoading(false);
        }
        if (!upHook.data) {
          setErr(upHook.message);
          setLoading(false);
        }
      } else {
        setErr("The new passwords do not match!");
        setLoading(false);
      }
    } catch (error) {
      console.log("Update failed", error);
      setErr("The Entered Current password is invalid, Try again!");
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Modify Password"
      open={open}
      onCancel={handleClose}
      footer={[
        <Space key="footer">
          <Button type="dashed" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Accept Change
          </Button>
        </Space>,
      ]}
    >
      <Typography.Paragraph style={{ padding: "1rem 0" }}>
        Endeavor to remember your Password always, otherwise several login
        attempts will lead to suspension of your account.
      </Typography.Paragraph>
      {err && (
        <Typography.Paragraph
          style={{
            color: "#C14D38",
            padding: "0.5rem 0",
            textAlign: "center",
            border: "1px solid #EED8D4",
            background: "#F9EEEC",
            borderRadius: "5px",
          }}
        >
          {err}
        </Typography.Paragraph>
      )}
      {succ && (
        <Typography.Paragraph
          style={{
            color: "#757676",
            padding: "0.5rem 0",
            textAlign: "center",
            border: "1px solid #B5E2D3",
            background: "#EDF6F3",
            borderRadius: "5px",
          }}
        >
          {succ}
        </Typography.Paragraph>
      )}
      <Form form={form} layout="vertical" onFinish={handleChange}>
        <Form.Item
          label="Enter Current Password"
          name="currentPassword"
          rules={[{ required: true, message: "Old password is required!" }]}
        >
          <Input.Password
            placeholder="Enter your current password"
            onChange={() => setErr("")}
          />
        </Form.Item>
        <Form.Item
          label="Enter New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please enter your new password" },
          ]}
        >
          <Input.Password
            placeholder="Enter your new password"
            minLength={8}
            onChange={() => setErr("")}
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="newPassword1"
          rules={[{ required: true, message: "Please confirm your password" }]}
        >
          <Input.Password
            placeholder="Re-enter your new password"
            minLength={8}
            onChange={() => setErr("")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PasswordModal;
