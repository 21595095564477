import {
  AreaChart,
  XAxis,
  Area,
  Tooltip,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Legend,
  ComposedChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";

function MainBarChart({ transArray, screenWidth }) {
  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-45)"
        >
          {`${payload.value / 1000}k`}
        </text>
      </g>
    );
  };
  return (
    <BarChart
      width={screenWidth < 800 ? 300 : 450}
      height={screenWidth < 800 ? 200 : 250}
      data={transArray.slice(0, 10)}
      margin={{
        top: 20,
        right: 5,
        left: 5,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Customer" />
      <YAxis
        tickFormatter={(value) => `${value / 1000}k`}
        tick={<CustomYAxisTick />}
      />
      <Tooltip />
      <Legend />
      <Bar dataKey="amount" stackId="a" fill="#F08D17" />
      {/* <Bar dataKey="amount" stackId="a" fill="#2c6f8d60" /> */}
    </BarChart>
  );
}

export default MainBarChart;
