import { Avatar, Col, Row, Space, Image } from "antd";
import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import SmallMenuModal from "./SmallMenuModal";
import { useNavigate } from "react-router-dom";

function HeaderSmall({ user }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "2vh 5vw",
      }}
    >
      <Row align="middle">
        <Col span={18} style={{ textAlign: "left" }}>
          <Space size={[15]}>
            <MenuOutlined
              onClick={() => setOpen(true)}
              style={{ fontSize: "24px" }}
            />
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          {user.image ? (
            <Image
              src={user.image}
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                border: "0.5px solid #ccc",
              }}
              preview={false}
              onClick={() => navigate("/settings")}
            />
          ) : (
            <Avatar
              icon={<UserOutlined />}
              onClick={() => navigate("/settings")}
            />
          )}
        </Col>
      </Row>

      <SmallMenuModal open={open} handleClose={handleClose} />
    </div>
  );
}

export default HeaderSmall;
