import {
  SettingOutlined,
  HomeOutlined,
  QrcodeOutlined,
  TeamOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

export const menuItems = [
  { key: "1", label: "Dashboard", icon: <HomeOutlined />, path: "/" },
  {
    key: "2",
    label: "QR Code",
    icon: <QrcodeOutlined />,
    path: "/qr-code",
  },
  {
    key: "3",
    label: "Transactions",
    icon: <FileSyncOutlined />,
    path: "/transactions",
  },
  {
    key: "4",
    label: "Customers",
    icon: <TeamOutlined />,
    path: "/customers",
  },
  {
    key: "5",
    label: "Settings",
    icon: <SettingOutlined />,
    path: "/settings",
  },
];
