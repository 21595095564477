import { Layout, Menu, Image, ConfigProvider, Divider } from "antd";
import { useLocation, Link } from "react-router-dom";
import { menuItems } from "../helpers/MenuList";
import purseLogo from "../images/purse_logo_long_white.png";
import purseHalfLogo from "../images/purse_logo_white.png";
const { Sider } = Layout;

function SideNav({ collapsed }) {
  const location = useLocation();

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#424242",
            fontFamily: "sans-serif",
          },
        }}
      >
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={80}
          style={{ background: "#000" }}
        >
          <div
            style={{
              padding: "2rem",
              color: "#fff",
              fontWeight: 700,
              fontSize: "1.5rem",
            }}
          >
            {collapsed ? (
              <Image src={purseHalfLogo} preview={false} width={"100%"} />
            ) : (
              <Image src={purseLogo} preview={false} width={"80%"} />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            style={{ background: "#000" }}
            defaultSelectedKeys={["1"]}
            selectedKeys={[location.pathname]}
          >
            {menuItems.map((item) => (
              <Menu.Item key={item.path} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      </ConfigProvider>
    </>
  );
}

export default SideNav;
