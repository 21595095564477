import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Row, Col, Form, Space, notification } from "antd";
import { setAlert } from "../../features/alertStateSlice/alertSlice";
import { updateMerchant, getMerchantSettings, updateSettings } from "../../api/merchant.module";
import BasicInfo from "../../components/settings/BasicInfo";
import BankDetails from "../../components/settings/BankDetails";
import NotificationSetting from "../../components/settings/NotificationSetting";
import { useSelector } from "react-redux";
import Spiner from "../../helpers/SpinComponent";
import CloseAccount from "../../components/settings/CloseAccount";
function Settings({ screenWidth }) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState("");
  const [edit, setEdit] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.value);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  const handleSubmit = async (v) => {
    setLoading(true);
    try {
      const submitReply = await updateSettings(user.id, v);
      if (submitReply) {
        openNotification(
          "success",
          "Successfully saved settings data",
          "Your changes have been saved successfully!"
        );
      } else {
        openNotification(
          "error",
          "Failed to save data",
          "This could be due to network, try again later!"
        );
      }
      setLoading(false);
    } catch (error) {
      openNotification(
        "error",
        "Failed to save data",
        "This could be due to network, try again later!"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const Xy = await getMerchantSettings(user.id);
      setUserData(Xy);
    };
    fetchData();
  }, [user.id]);
  return (
    <div>
      {contextHolder}
      <Row
        style={{
          background: "#fff",
          padding: "0.3rem 0.5rem",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Col span={12}>
          <Space>
            <Button type="dashed" onClick={() => setEdit((c) => !c)}>
              {!edit ? "Cancel" : "Enable"} Edit
            </Button>
            {!edit && (
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
              >
                Save Changes
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      {userData ? (
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={userData}
          form={form}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12}>
              <BankDetails edit={edit} />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <BasicInfo edit={edit} />
              <CloseAccount edit={edit} />
            </Col>
          </Row>
        </Form>
      ) : (
        <Spiner title="settings" />
      )}
    </div>
  );
}

export default Settings;
