import React, { useState } from "react";
import { Breadcrumb, Button, Image, Layout, Space, Typography } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AvatarContainer from "../helpers/AvatarContainer";
import AccountSettings from "./AccountSettings";

const { Header } = Layout;
const { Text, Paragraph } = Typography;
function HeaderTop({ collapsed, setCollapsed }) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const user = useSelector((state) => state.auth.value);
  const url = useLocation().pathname;
  const segments = url.split("/").slice(1);
  return (
    <Header
      style={{
        padding: 0,
        background: "#fff",
        // display: "flex-end",
      }}
    >
      <Space>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <Breadcrumb
          items={segments.map((item) => ({
            title: <span>{item.charAt(0).toUpperCase() + item.slice(1)}</span>,
          }))}
        />
      </Space>
      <Paragraph
        style={{
          textAlign: "right",
          background: "#cccccc40",
          borderRadius: "8px",
          padding: "4px 15px",
          margin: "6px 16px 0 0",
          float: "right",
        }}
        onClick={showDrawer}
      >
        <Space size={15}>
          {user.image ? (
            <Image
              src={user.image}
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            />
          ) : (
            <AvatarContainer name={user.name} />
          )}
          <Paragraph style={{ margin: 0, textAlign: "left" }}>
            <Text strong>{user.name}</Text> <br />
            <Text style={{ color: "#888" }}> Merchant</Text>
          </Paragraph>
          <DownOutlined style={{ fontSize: "18px" }} />
        </Space>
      </Paragraph>
      {open && <AccountSettings onClose={onClose} open={open} />}
    </Header>
  );
}

export default HeaderTop;
