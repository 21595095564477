import { Route, Routes } from "react-router-dom";
import LayOut from "./account/LayOut";
import DashboardIndex from "./account/subpages/DashboardIndex";
import Login from "./account/Login";
import QRPage from "./account/subpages/QRPage";
import Transactions from "./account/subpages/Transactions";
import Settings from "./account/subpages/Settings";
import { ConfigProvider } from "antd";
import NotFound from "./account/NotFound";
import VerifyAccount from "./components/VerifyAccount";
import { useState, useEffect } from "react";
import Customers from "./components/pages/Customers";
import ChatIndex from "./account/subpages/chats/ChatIndex";
import ChatClients from "./account/subpages/chats/ChatClients";

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Function to update screenWidth when the window is resized
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };
  // Add a resize event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#141414",
            fontFamily: "sans-serif",
            colorText: "#424242",
            fontSizeIcon: "18px",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<LayOut screenWidth={screenWidth} />}>
            <Route
              index
              element={<DashboardIndex screenWidth={screenWidth} />}
            />
            <Route
              path="/transactions"
              element={<Transactions screenWidth={screenWidth} />}
            />
            <Route
              path="/qr-code"
              element={<QRPage screenWidth={screenWidth} />}
            />
            <Route
              path="/settings"
              element={<Settings screenWidth={screenWidth} />}
            />
            <Route
              path="/customers"
              element={<Customers screenWidth={screenWidth} />}
            >
              <Route
                path="/customers"
                index
                element={<ChatIndex screenWidth={screenWidth} />}
              />
              <Route
                path="/customers/:id"
                element={<ChatClients screenWidth={screenWidth} />}
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/merchant/verify-account/:username/:code"
            element={<VerifyAccount />}
          />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
