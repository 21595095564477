import { Typography, Button, Image } from "antd";
import purseLogo from "../images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spiner from "../helpers/SpinComponent";
import { checkLink } from "../api/merchant.module";
const { Paragraph, Text } = Typography;
function VerifyAccount() {
  const [values, setValues] = useState("");
  const navigate = useNavigate();
  const { username, code } = useParams();
  const verifyEmail = async () => {
    try {
      const dt = await checkLink(username, code);
      if (dt.data) {
        setValues(dt.data);
      } else {
        setValues("none");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    verifyEmail();
  }, []);
  return (
    <div
      style={{
        margin: "2% 25%",
        background: "#49402810",
        padding: "2rem 2.5rem",
        fontFamily: "Arial Narrow",
      }}
    >
      <Paragraph style={{ textAlign: "center" }}>
        <Image src={purseLogo} alt="purse-logo" width={120} />
      </Paragraph>
      {!values ? (
        <Spiner />
      ) : (
        <>
          {values === "none" ? (
            <>
              <Paragraph
                style={{
                  padding: "1rem 2rem",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    color: "#B94004",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  Oops Sorry, Verification failed!!!
                </Text>
                <br />
                <Text style={{ color: "#888" }}>
                  This could be due to an invalid activation link
                </Text>
              </Paragraph>
              <Paragraph style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => navigate("/login")}>
                  Contact Admin
                </Button>
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph style={{ textAlign: "center" }}>
                <Text
                  style={{ color: "#888", fontWeight: 700, fontSize: "32px" }}
                >
                  Congratulations!!!
                </Text>
                <br /> Your email address has been verified!
              </Paragraph>
              <Paragraph>
                Hello <b>{values?.name}</b>,
              </Paragraph>
              <Paragraph>
                Congratulations on successfully verifying your email! You can
                now access all the wonderful features and rewards available with
                our purse wallet.
              </Paragraph>
              <Paragraph style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => navigate("/login")}>
                  Continue to Login
                </Button>
              </Paragraph>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default VerifyAccount;
