import {
  Card,
  Form,
  Row,
  Col,
  Switch,
  Typography,
  Divider,
  Button,
} from "antd";

const { Text, Title } = Typography;
function NotificationSetting({ edit }) {
  return (
    <>
      <Card>
        <Title level={5}>Notifications Settings</Title>
        <Text>
          Manage the categories of notifications you want to be notified about.
        </Text>
        <Divider />
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "systemAlerts"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}> When a System changes</Text>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "b2cPay"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}>
              When a customer makes a payment
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "b2bPay"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}>
              When a merchant makes a payment
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "chatOnCustomer"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}>
              When a customer sends a message
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "chatOnMerchant"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}>
              When a merchant sends a message
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Item
              name={["notifications", "payEffect"]}
              valuePropName="checked"
            >
              <Switch size="small" disabled={edit} />
            </Form.Item>
          </Col>
          <Col md={20} style={{ padding: "0.3rem" }}>
            <Text style={{ margin: "0 0.5rem" }}>
              When a payment have been effected
            </Text>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default NotificationSetting;
