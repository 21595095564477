import { Card, Typography, Row, Tag, Col } from "antd";
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

function TopSalesCards({ user, screenWidth, transactions, uniqueCustomers }) {
  const gridStyle = {
    width: screenWidth < 600 ? "100%" : "23%",
    minWidth: screenWidth < 600 ? "200px" : "230px",
    padding: "0.7rem 1rem",
    borderRadius: "10px",
    border: "1px solid #ddcddc50",
    boxShadow: "none",
    boxSizing: "border-box",
    flexGrow: 1,
    margin: "5px",
    backgroundColor: "#fff",
  };
  // let totalRevenue = 0;
  // for (const obj of transArray) {
  //   totalRevenue += obj.amount;
  // }

  return (
    <>
      {screenWidth < 800 ? (
        <div>
          <Card
            style={{ background: "linear-gradient(45deg,  #7c2, #0c6f8d)" }}
          >
            <Paragraph style={{ color: "#fff", fontSize: "16px" }}>
              Current Balance
            </Paragraph>
            <Paragraph
              style={{
                fontSize: "32px",
                color: "#f1f1f1",
                margin: 0,
                fontWeight: 700,
              }}
            >
              {user.accountBalance
                ? `₦ ${user.accountBalance?.toLocaleString()}`
                : "0.00"}
            </Paragraph>
          </Card>
        </div>
      ) : (
        <div
          style={{
            margin: screenWidth < 800 ? "0" : "1rem 0",
            border: "none",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={gridStyle}>
            <Row>
              <Col span={24}>
                <Paragraph strong style={{ margin: 0 }}>
                  Current Balance
                </Paragraph>
              </Col>
            </Row>
            <Paragraph
              style={{
                fontSize: "calc(1.7rem + .45vw)",
                fontWeight: 600,
                margin: "2px",
                paddingTop: "0.5rem",
              }}
            >
              ₦ {user.accountBalance?.toLocaleString()}
            </Paragraph>
          </div>
          <div style={gridStyle}>
            <Row>
              <Col span={18}>
                <Paragraph strong style={{ margin: 0 }}>
                  Total Customers
                </Paragraph>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <Tag color="cyan" icon={<RiseOutlined />}>
                  10%
                </Tag>
              </Col>
            </Row>
            <Paragraph
              style={{
                margin: 0,
                lineHeight: 0.5,
              }}
            >
              <Text style={{ fontSize: "calc(1.5rem + .45vw)" }} strong>
                {uniqueCustomers?.length}
              </Text>
              <br />
              <Text style={{ color: "#05AF86", fontSize: "11px" }}>10% </Text>
              <Text style={{ color: "#888", fontSize: "11px" }}>
                increase compared to last month
              </Text>
            </Paragraph>
          </div>

          <div style={gridStyle}>
            <Row>
              <Col span={18}>
                <Paragraph strong style={{ margin: 0 }}>
                  Total Sales
                </Paragraph>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <Tag color="cyan" icon={<RiseOutlined />}>
                  10%
                </Tag>
              </Col>
            </Row>
            <Paragraph
              style={{
                margin: 0,
                lineHeight: 1,
              }}
            >
              <Text style={{ fontSize: "calc(1.5rem + .45vw)" }} strong>
                {transactions.length}
              </Text>
              <br />
              <Text style={{ color: "#05AF86", fontSize: "11px" }}>10% </Text>
              <Text style={{ color: "#888", fontSize: "11px" }}>
                increase compared to last month
              </Text>
            </Paragraph>
          </div>

          <div style={gridStyle}>
            <Row>
              <Col span={18}>
                <Paragraph strong style={{ margin: 0 }}>
                  Active Customers
                </Paragraph>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <Tag color="gold" icon={<FallOutlined />}>
                  6%
                </Tag>
              </Col>
            </Row>
            <Paragraph
              style={{
                margin: 0,
                lineHeight: 1,
              }}
            >
              <Text style={{ fontSize: "calc(1.5rem + .45vw)" }} strong>
                {uniqueCustomers?.length}
              </Text>
              <br />
              <Text style={{ color: "#de2411", fontSize: "11px" }}>6% </Text>
              <Text style={{ color: "#888", fontSize: "11px" }}>
                decrease compared to last month
              </Text>
            </Paragraph>
          </div>
        </div>
      )}
    </>
  );
}

export default TopSalesCards;
