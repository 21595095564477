import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Empty,
  Button,
  DatePicker,
  Space,
  Table,
  Tag,
} from "antd";
import { useSelector } from "react-redux";
import { ResponsiveContainer } from "recharts";
import MainBarChart from "../../components/re-chats/MainBarChart";
// import MainAreaChart from "../../components/re-chats/MainAreaChart";
import { getMerchantTransactions } from "../../api/merchant.module";
import TopSalesCards from "../../components/accounts/TopSalesCards";
import Spiner from "../../helpers/SpinComponent";
import { useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import AvatarContainer from "../../helpers/AvatarContainer";
import MainPieChart from "../../components/re-chats/MainPieChart";

const { Column } = Table;
const { Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
function DashboardIndex({ screenWidth }) {
  const [transactions, setTransactions] = useState("");
  const user = useSelector((state) => state.auth.value);
  const accountBalance = user.accountBalance;
  const navigate = useNavigate();
  const gridStyle = {
    width: screenWidth < 600 ? "100%" : "49%",
    paddingBottom: "1rem",
    borderRadius: "10px",
    border: "1px solid #ddcddc50",
    margin: screenWidth < 600 ? "0.5rem 0 0 0" : "5px",
    backgroundColor: "#fff",
  };
  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const transactionList = await getMerchantTransactions(user.id);
        setTransactions(transactionList);
      } catch (err) {
        console.log("error", err);
      }
    };
    fetchDeposits();
  }, [user.id]);
  const transArray =
    transactions &&
    transactions.map((x) => ({
      Customer: x.customer.name.toUpperCase().slice(0, 1),
      amount: x.amount,
    }));
  const newList =
    transactions &&
    transactions.map((transaction) => {
      return {
        customerId: transaction.customerId,
        name: transaction.customer.name,
      };
    });
  // function uniqueCustomers(newLi) {
  //   return [...new Set(newLi)];
  // }
  function uniqueObjectsByProperty(objects, propertyKey) {
    const seen = new Set();
    const uniqueCustomers = [];

    for (const obj of objects) {
      const value = obj[propertyKey];
      if (!seen.has(value)) {
        seen.add(value);
        uniqueCustomers.push(obj);
      }
    }

    return uniqueCustomers;
  }
  const uniqueCustomers = uniqueObjectsByProperty(newList, "customerId");
  // console.log("nssss", uniqueCustomers);
  transactions &&
    transactions.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  return (
    <div>
      <TopSalesCards
        transactions={transactions}
        accountBalance={accountBalance}
        uniqueCustomers={uniqueCustomers}
        user={user}
        screenWidth={screenWidth}
      />

      <div
        style={{
          margin: 0,
          border: "none",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div style={gridStyle}>
          <Row
            gutter={5}
            style={{ padding: "0.2rem 0", margin: "0.5rem 1rem 0 1rem" }}
          >
            <Col md={12}>
              <Paragraph>
                <Text strong>Customers</Text>
              </Paragraph>
            </Col>
            <Col md={12}>{/* <Space>Select</Space> */}</Col>
          </Row>
          {!transactions ? (
            <Spiner />
          ) : transactions.length < 1 ? (
            <Empty
              description="No transactions yet!"
              style={{ padding: "9%" }}
            />
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <MainBarChart transArray={transArray} screenWidth={screenWidth} />
            </ResponsiveContainer>
          )}
        </div>
        <div style={gridStyle}>
          <Row
            gutter={5}
            style={{ padding: "0.2rem 0", margin: "0.5rem 1rem 0 1rem" }}
          >
            <Col md={12}>
              <Paragraph>
                <Text strong>Payments</Text>
              </Paragraph>
            </Col>
            <Col md={12}>{/* <Space>Select</Space> */}</Col>
          </Row>
          {!transactions ? (
            <Spiner />
          ) : transactions.length < 1 ? (
            <Empty
              description="No transactions yet!"
              style={{ padding: "9%" }}
            />
          ) : (
            <ResponsiveContainer width="100%">
              <MainPieChart transArray={transArray} screenWidth={screenWidth} />
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div style={{ margin: "1rem 0rem", padding: "1px" }}>
        <Row gutter={5} align="middle">
          <Col span={16}>
            <Paragraph strong>TOP TRANSACTIONS</Paragraph>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Paragraph>
              <Button type="link" onClick={() => navigate("/transactions")}>
                View All{" "}
              </Button>
            </Paragraph>
          </Col>
        </Row>
        {!transactions ? (
          <Spiner />
        ) : transactions.length < 1 ? (
          <Empty description="No transactions yet!" style={{ padding: "9%" }} />
        ) : (
          <div style={{ paddingBottom: "0.5rem" }}>
            {screenWidth < 800 ? (
              <>
                {transactions?.slice(0, 5).map((x) => {
                  return (
                    <Paragraph
                      style={{
                        background: "#f1f1f110",
                        border: "0.2px solid #eee",
                        borderRadius: "3px",
                        margin: "0.3rem 0",
                        padding: "0.5rem 1rem",
                        lineHeight: 1,
                      }}
                      key={x.id}
                    >
                      <Row gutter={4} align="middle">
                        <Col span={5}>
                          {x.customer.image ? (
                            <Image
                              src={x.customer.image}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                              preview={false}
                            />
                          ) : (
                            <AvatarContainer name={x.customer.name} size={45} />
                          )}
                        </Col>
                        <Col span={12} style={{ padding: "0.5rem 0" }}>
                          <Text strong>{x.customer.name} </Text> <br />
                          <Text style={{ color: "#888" }}>
                            {x.reason ? x.reason : "Not provided"}
                          </Text>{" "}
                          |{" "}
                          <Text style={{ color: "#dd1", fontSize: "12px" }}>
                            <TimeAgo date={x.createdAt} />
                          </Text>
                        </Col>
                        <Col span={6} style={{ textAlign: "right" }}>
                          <Text style={{ fontSize: "#123" }} strong>
                            ₦ {(x.amount - x.merchantCharges)?.toLocaleString()}
                          </Text>
                        </Col>
                      </Row>
                    </Paragraph>
                  );
                })}
              </>
            ) : (
              <Table
                dataSource={transactions.slice(0, 5)}
                rowKey="id"
                style={{ border: "1px solid #cccccc50" }}
              >
                <Column
                  title="CUSTOMER"
                  dataIndex="customer"
                  render={(y) => (
                    <>
                      <Space>
                        {y.image ? (
                          <Image
                            src={y.image}
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                            preview={false}
                          />
                        ) : (
                          <AvatarContainer name={y.name} />
                        )}

                        {y.name?.toUpperCase()}
                      </Space>
                    </>
                  )}
                />
                <Column
                  title="DESCRIPTION"
                  dataIndex="reason"
                  render={(x) => <>{x ? x : "Not provided"}</>}
                />
                <Column
                  title="STATUS"
                  dataIndex="reason"
                  render={(x) => (
                    <>
                      <Tag color="green">Success</Tag>
                    </>
                  )}
                />
                <Column
                  title="CREDITS"
                  align="right"
                  dataIndex="amount"
                  render={(amount, x) =>
                    `₦ ${(amount - x.merchantCharges)?.toLocaleString()}`
                  }
                />
                <Column
                  title="TIME"
                  dataIndex="createdAt"
                  render={(x) => <TimeAgo date={x} />}
                />
              </Table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardIndex;
