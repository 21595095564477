import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  QRCode,
  Typography,
  Divider,
  Form,
  Input,
  Space,
} from "antd";
import {
  PlusOutlined,
  MoreOutlined,
  EyeFilled,
  DeleteFilled,
  PrinterOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import purseLogoOrange from "../../images/purse_logo_orange.png";

const { Text, Paragraph } = Typography;
function QRPage({ screenWidth }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.value);
  let componentRef = React.createRef();
  const handleOrderPlacement = (v) => {
    setLoading(true);
    console.log(v);
    setLoading(false);
  };
  return (
    <>
      <Row gutter={12}>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={12}
          style={{
            padding: "0 2rem",
            background: "#cccccc30",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            ref={componentRef}
            style={{
              padding: "10vh 2vw",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <QRCode
              value={`https://purse.vercel.app/b2c/${user.qrCodeId}`}
              size={280}
              errorLevel="H"
              icon={purseLogoOrange}
            />
          </div>

          <Paragraph>
            <ReactToPrint
              trigger={() => (
                <Button type="dashed" icon={<PrinterOutlined />}>
                  Print QR Code
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Paragraph>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={12}
          style={{ padding: screenWidth < 800 ? "1rem 0" : "0 2rem" }}
        >
          <Paragraph style={{ fontSize: "18px" }} strong>
            Merchant QR Details
          </Paragraph>
          <Row gutter={12}>
            <Col span={12} style={{ lineHeight: 2 }}>
              <Text>Merchant Name</Text>
              <br />
              <Paragraph
                style={{
                  background: "#cccccc50",
                  padding: "5px 10px",
                  borderRadius: "4px",
                }}
              >
                {user?.name}
              </Paragraph>
            </Col>
            <Col span={12} style={{ lineHeight: 2 }}>
              <Text>QR Code ID</Text>
              <br />
              <Space
                style={{
                  background: "#cccccc50",
                  padding: "5px 10px",
                  borderRadius: "4px",
                }}
              >
                <Paragraph style={{ margin: 0 }}>{user?.qrCodeId}</Paragraph>
                <Paragraph
                  style={{ margin: 0 }}
                  copyable={{ text: user.qrCodeId }}
                ></Paragraph>
              </Space>
            </Col>
          </Row>
          <Divider dashed style={{ marginBottom: "4vh" }} />
          <Paragraph
            style={{
              background: "#F3640710",
              padding: screenWidth < 600 ? "1vh 3vw" : "1em 1.5em",
              borderRadius: "5px",
              border: "1px solid #F3640760",
              color: "#00000095",
              lineHeight: 1.4,
            }}
          >
            To facilitate customer payments, merchants can generate and display
            a QR code on the right. Customers can conveniently scan this QR code
            using their smartphones to initiate and complete the payment
            process.
          </Paragraph>
          <Paragraph>
            To have the card delivered to your doorstep, just enter your
            information and address.
          </Paragraph>
          <Form
            layout="vertical"
            name="oder-form"
            onFinish={handleOrderPlacement}
          >
            <Form.Item name="address">
              <Input placeholder="Address" />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item name="city">
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="state">
                  <Input placeholder="state" />
                </Form.Item>
              </Col>
            </Row>
            <Paragraph style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Place Order
              </Button>
            </Paragraph>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default QRPage;
