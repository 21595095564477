import { Form, Input , Typography } from "antd";


const { Paragraph, Text } = Typography;
function Register({ setErr }) {
  return (
    <>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please provide your full names!",
          },
        ]}
      >
        <Input placeholder="Full name" />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: "Please provide your phone number!",
          },
        ]}
      >
        <Input
          addonBefore="+234"
          placeholder="Phone Number"
          maxLength={10}
          style={{ maxWidth: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please provide the your email address!",
          },
        ]}
      >
        <Input
          placeholder="Email address"
          type="email"
          style={{ maxWidth: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please ths field is required!",
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
          type="password"
          onChange={() => setErr("")}
        />
      </Form.Item>
      <Form.Item
        name="passwordTwo"
        rules={[
          {
            required: true,
            message: "Please provide a matching password!",
          },
        ]}
      >
        <Input.Password
          placeholder="Confirm password"
          type="password"
          onChange={() => setErr("")}
        />
      </Form.Item>
      <Paragraph>
        By creating an account, you acknowledge you have read and agreed to our{" "}
        <Text strong style={{ color: "#000", cursor: "pointer" }}>
          Terms of Use and Privacy Policy
        </Text>
      </Paragraph>
    </>
  );
}

export default Register;
