import axios from "axios";

// const uza = localStorage.getItem("merchant");
// const user = uza && JSON.parse(atob(uza.split(".")[1]));
// const header = { "x-access-token": uza };

const { REACT_APP_SERVER_URL } = process.env;

export async function loginMerchant(value) {
  const { data } = await axios.post(
    `${REACT_APP_SERVER_URL}/merchants/login`,
    value
  );
  return data;
}

export async function confirmPassword(val) {
  try {
    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/merchants/psw`,
      val
    );
    return data;
  } catch (error) {
    return false;
  }
}

export async function updateMerchant(id, val) {
  try {
    const { data } = await axios.patch(
      `${REACT_APP_SERVER_URL}/merchants/${id}`,
      val
    );
    return data;
  } catch (error) {
    return false;
  }
}

export async function checkLink(username, code) {
  const kg = await axios.get(
    `${REACT_APP_SERVER_URL}/merchants/confirm-email/${username}/${code}`
  );
  return kg.data;
}

export async function createMerchant(dat) {
  const data = await axios.post(`${REACT_APP_SERVER_URL}/merchants`, dat);
  return data;
}

export async function getMerchantTransactions(id) {
  const { data } = await axios.get(
    `${REACT_APP_SERVER_URL}/merchants/transactions/${id}`
  );
  return data.data;
}

export async function createMerchantTransaction(dat) {
  const { data } = await axios.post(
    `${REACT_APP_SERVER_URL}/merchants/transactions/`,
    dat
  );
  return data;
}

export async function updateSettings(id, DATA) {
  const { data } = await axios.post(
    `${REACT_APP_SERVER_URL}/merchants/settings/${id}`,
    DATA
  );
  return data;
}

export async function getMerchant(id) {
  const { data } = await axios.get(`${REACT_APP_SERVER_URL}/merchants/${id}`);
  return data.data;
}

export async function getMerchantData(id) {
  const fetchQuery = await axios.get(
    `${REACT_APP_SERVER_URL}/merchants/data/${id}`
  );
  return fetchQuery.data;
}

export async function getMerchantSettings(id) {
  const { data } = await axios.get(
    `${REACT_APP_SERVER_URL}/merchants/settings/${id}`
  );
  return data;
}

export async function updateMerchantPassword(id, psw) {
  const kg = await axios.patch(
    `${REACT_APP_SERVER_URL}/merchants/psw/${id}`,
    psw
  );
  return kg.data;
}
